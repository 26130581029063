
export default defineNuxtRouteMiddleware((to) => {
  const { $localePath } = useNuxtApp();
  const $auth = useAuth();

  if (!$auth.loggedIn) return;

  const redirectPath = to.query.redirectTo as string
    || window.localStorage?.getItem('redirectTo')
    || $localePath({ name: 't-tenantSlug-m', params: { tenantSlug: $auth.user?.workspace.slug } });

  window.localStorage?.removeItem('redirectTo');

  // eslint-disable-next-line consistent-return
  return navigateTo(redirectPath);
});
